import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import classNames from "classnames";
import Form from "../../../components/UI/Form/Form";
import Button from "../../../components/Button/Button";
import { MAX_INPUT_LENGTH } from "../../../global/constants";
import { useNavigate } from "react-router-dom";
import { sendPasswordRecoveryEmail } from "../../../services/security-services/SecurityActions";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  email: yup.string().email().required().trim().max(MAX_INPUT_LENGTH),
});

const ForgotPasswordTitle = () => {
  const navigate = useNavigate();

  const onBackClick = () => navigate("/login");

  return (
    <span className="d-flex">
      <button 
        className="btn btn-sm p-0 mr-3 h-100 d-flex align-items-center" 
        onClick={onBackClick}
        title="Back to login page"
      >
        <i className="fas fa-chevron-left fa-2x text-fourth"/>
      </button>
      <span>Forgot password</span>
    </span>
  );
}

export default function ForgotPasswordForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    const success = await dispatch(sendPasswordRecoveryEmail(data.email));
    setIsLoading(false);

    if (success) {
      navigate('/login');
      setTimeout(() => {
        toast.success("An email has been sent to the provided email address with further instructions.");
      }, 0);
    } else {
      toast.info("This email address is not registered in the system.");
    }
  };

  return (
    <>
      <Form 
        title={<ForgotPasswordTitle />} 
        formDialog={false} 
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form-group text-left">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            name="email"
            placeholder="Enter email here..."
            {...register("email")}
            id="email"
            className={classNames("form-control shadow-sm", {
              "is-invalid": errors.email,
            })}
          />
          <div className={classNames({"invalid-feedback": errors.email})}>
            {errors.email?.message}
          </div>
        </div>

        <Button loading={isLoading} type="submit">
          Recover a password
        </Button>
      </Form>
    </>
  );
};

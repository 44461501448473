import axios from "axios";
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT } from "../types";
//import jwt_decode from "jwt-decode";
import { AAS_PREFIX, JWT_TOKEN, PORTFOLIO } from "../../global/constants";
import setJWTToken from "../../securityUtils/setJWTToken";

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  payload: data,
});
export const loginError = (err) => ({
  type: LOGIN_ERROR,
  payload: err,
});

export const logout = () => ({ type: LOGOUT });

export const login = (LoginRequest) => async (dispatch) => {
  setJWTToken(null);
  localStorage.removeItem(PORTFOLIO);
  dispatch(loginRequest());
  try {
    const res = await axios.post(`${AAS_PREFIX}/authenticate`, LoginRequest);
    const token = res.data.jwt;
    localStorage.setItem("username", LoginRequest.username);
    localStorage.setItem(JWT_TOKEN, token);
    setJWTToken(token);
    dispatch(loginSuccess());
  } catch (err) {
    dispatch(loginError("Bad credentials. Please try again."));
  }
};

export const sendPasswordRecoveryEmail = (email) => async (dispatch) => {
  try {
    await axios.post(`${AAS_PREFIX}/users/forgot-password?email=${email}`);
    return true;
  } catch (err) {
    return false;
  }
};

export const resetPassword = (request) => async (dispatch) => {
  try {
    await axios.post(`${AAS_PREFIX}/users/reset-password`, request);
    return true;
  } catch (err) {
    return false;
  }
};

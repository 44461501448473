import jwt_decode from "jwt-decode";
import {
  JWT_TOKEN,
  ROLE_ADMIN,
  ROLE_COLLABORATOR,
  ROLE_PORTFOLIO_MANAGER,
  ROLE_PROJECT_MANAGER,
  ROLE_RESOURCE_MANAGER,
  ROLE_SYSTEM_ADMIN,
  ROLE_VIEWER,
  USER_ADMIN,
  USER_EDITOR,
} from "../../global/constants";
import {
  HIDE_PORTFOLIO,
  TASK_MANAGEMENT,
  VISUAL_PLANNING,
  ZERO_ON_QUANTITY,
} from "../../licenseData";

export const allowFeature = (feature) => {
  try {
    const { lic } = jwt_decode(localStorage.getItem(JWT_TOKEN));
    if (!lic) return false;

    return lic.includes(feature.trim());
  } catch (error) {
    //console.error(error);
  }
};

export const allowByRole = (...args) => {
  try {
    let isAllowed = false;
    const { roles } = jwt_decode(localStorage.getItem(JWT_TOKEN));

    if (!roles) return isAllowed;

    const rolesArray = Array.isArray(args) ? args : [args];
    isAllowed = rolesArray.some((role) => roles.includes(role));
    return isAllowed;
  } catch (error) {
    //console.error(error);
  }
};

export const findMinimunQuantity = () => {
  const { lic } = jwt_decode(localStorage.getItem(JWT_TOKEN));
  if (lic && lic.indexOf(ZERO_ON_QUANTITY) !== -1) {
    return 0;
  } else {
    return 1;
  }
};

export const visualPlanningPermission = () => {
  return allowFeature(VISUAL_PLANNING);
};

export const metricsPermission = () => {
  return (
    !allowFeature(HIDE_PORTFOLIO) &&
    allowByRole(ROLE_PORTFOLIO_MANAGER, ROLE_PROJECT_MANAGER, ROLE_ADMIN)
  );
};

export const workloadPermission = () => {
  return allowByRole(
    ROLE_RESOURCE_MANAGER,
    ROLE_PROJECT_MANAGER,
    ROLE_PORTFOLIO_MANAGER,
    ROLE_ADMIN
  );
};

export const isAdmin = () => allowByRole(ROLE_ADMIN, ROLE_SYSTEM_ADMIN);

export const isSecurityAdmin = () =>
  allowByRole(ROLE_ADMIN, ROLE_SYSTEM_ADMIN, ROLE_PORTFOLIO_MANAGER);

export const isResourceManager = () => allowByRole(ROLE_RESOURCE_MANAGER);

export const myTasksPermission = () => {
  return (
    allowFeature(TASK_MANAGEMENT) &&
    allowByRole(ROLE_RESOURCE_MANAGER, ROLE_PORTFOLIO_MANAGER, ROLE_ADMIN)
  );
};

export const libraryPermission = () =>
  !allowByRole(ROLE_VIEWER, ROLE_COLLABORATOR);

export const resourcePermission = () => {
  return allowByRole(ROLE_PORTFOLIO_MANAGER, ROLE_ADMIN);
};

export function isAllowedToEditProjectCollaborators() {
  return allowByRole(ROLE_PORTFOLIO_MANAGER, ROLE_ADMIN, ROLE_SYSTEM_ADMIN);
}

const DEFAULT_PERMISSIONS = {
  allowedToRead: true,
  allowedToUpdate: false,
  allowedToWrite: false,
  allowedToDelete: false,
};

const DEFAULT_ADMIN_PERMISSIONS = {
  allowedToRead: true,
  allowedToUpdate: true,
  allowedToWrite: true,
  allowedToDelete: true,
};

export function computeUsersProjectPermissions(
  projectPermissions = {},
  projectId
) {
  if (isAllowedToEditProjectCollaborators()) return DEFAULT_ADMIN_PERMISSIONS;

  if (projectId in projectPermissions) return projectPermissions[projectId];

  return DEFAULT_PERMISSIONS;
}

export function computeRoleName(projectPermissions) {
  const isEditor =
    projectPermissions.allowedToRead &&
    projectPermissions.allowedToWrite &&
    projectPermissions.allowedToUpdate;
  if (isEditor && projectPermissions.allowedToDelete) return USER_ADMIN;
  else if (isEditor) return USER_EDITOR;
  return "viewer";
}

export const MULTI_SCOPE_FEATURE = "Multi-Scope";
export const SCOPE_CHANGE_FEATURE = "Scope Change";
export const PROJECT_SIMUlATION_FEATURE = "Simulation";
export const ONE_BLOCK_DUPLICATION_FEATURE = "One Block Duplication";
export const GROUP_BLOCK_DUPLICATION = "Group Block Duplication";
export const ACTUALS_CSV_IMPORT = "Actuals CSV Import";
export const CAPACITY_PLANNING = "Capacity Planning";
export const ZERO_ON_QUANTITY = "Zero On Quantity";
export const LIBRARY_CSV_IMPORT = "Library CSV Import";
export const TASK_MANAGEMENT = "Task Management";
export const SCOPE_TYPE = "Scope type";
export const REGULAR_TASK_EDIT = "Regular Task Edit";
export const INTER_SCOPE_MAPPING = "Scopes Mapping";
export const NLC_DIMENSION = "Non-labor Cost Dimension";
export const BLOCK_TYPES = "Block Types";
export const TEAM_USAGE = "Team Usage";
export const PROJECT_OBJECTIVES = "Project Objectives";
export const CRITICAL_CHAIN = "Critical Chain";
export const VISUAL_PLANNING = "Visual Planning";
export const SCENARIO_ASSUMPTIONS = "Scenario Assumptions";
export const LOOP_AND_BOTTLENECK = "Loop and Bottleneck";
export const HIDE_PORTFOLIO = "Hide Portfolio";
export const ASK_AI = "Ask AI";
export const DISPLAY_TIMELINES = "Display Timelines";
export const STARLIMS_API = "Starlims API";
export const SPLIT_TASK = "Split Task";

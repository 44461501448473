import {
  SELECT_REGULARS,
  SELECT_TASKS,
  UPDATE_GANTT_PERIOD,
  UPDATE_GANTT_SIZE,
  UPDATE_PANELS_HEIGHT,
  RESIZE_PIVOT_CHART,
  UPDATE_SELECTED_TAB_INDEX,
  SET_UPDATING_STATUS,
  SELECT_ROW,
  ALLOW_TO_RENDER,
  DISABLE_SAVING,
  SET_TASK_TO_ASSIGN,
  SET_ITEM_TO_EDIT,
  SET_MY_SCOPES_VIEW,
  CHANGE_SYNC_STATUS,
  RESET_UNSYNC_STATUS,
  SET_WORKLOAD_FILTERS,
  ADD_ITEM_PENDING,
  REMOVE_ITEM_PENDING,
  CLEAR_ITEMS_PENDING,
  SET_SELECTED_ACTIVITY,
  SET_REGULAR_TO_ASSIGN,
  SET_CHART_FILTERING,
  SET_WORK_DAYS_CALENDAR,
  RECREATE_SCHEDULER_INSTANCE,
  SET_GANTT_CUSTOM_COLUMNS,
  RESET_GANTT_CUSTOM_COLUMNS,
} from "../types";
import produce from "immer";

const initialState = {
  selectedRegulars: [],
  selectedTasks: [],
  ganttPeriod: {},
  ganttSize: {},
  ganttCustomColumns: [],
  chartIdResizing: null,
  selectedTabIndex: {},
  allowToRender: false,
};

const initialState2 = {
  panelsHeight: [],
  isUpdating: false,
  itemToEdit: null,
  chartFilter: [],
  isRecreating: false,
};

const initialState3 = {
  selectedRow: null,
  taskToAssign: null,
  myScopeView: null,
  workloadFilters: {
    resources: [],
    years: [],
    checkedFTE: false,
  },
};

const initialState4 = {
  disableSaving: false,
  itemsPending: [],
  selectedActivity: undefined,
  regularToAssign: null,
  calendar: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export function UtilsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SELECT_REGULARS:
      return produce(state, (draftState) => {
        draftState.selectedRegulars = payload;
      });
    case SELECT_TASKS:
      return produce(state, (draftState) => {
        draftState.selectedTasks = payload;
      });
    case UPDATE_GANTT_PERIOD:
      return produce(state, (draftState) => {
        draftState.ganttPeriod = payload;
      });
    case UPDATE_GANTT_SIZE:
      return produce(state, (draftState) => {
        draftState.ganttSize = payload;
      });
    case RESIZE_PIVOT_CHART:
      return produce(state, (draftState) => {
        draftState.chartIdResizing = payload;
      });
    case UPDATE_SELECTED_TAB_INDEX:
      return produce(state, (draftState) => {
        draftState.selectedTabIndex[payload.id] = payload.value;
      });
    case ALLOW_TO_RENDER:
      return produce(state, (draftState) => {
        draftState.allowToRender = payload;
      });
    case RESET_GANTT_CUSTOM_COLUMNS:
      return produce(state, (draftState) => {
        draftState.ganttCustomColumns = [];
      });
    case SET_GANTT_CUSTOM_COLUMNS:
      return produce(state, (draftState) => {
        draftState.ganttCustomColumns = payload;
      });
    default:
      return state;
  }
}

export function UtilsReducer2(state = initialState2, { type, payload }) {
  switch (type) {
    case UPDATE_PANELS_HEIGHT:
      if (!payload) {
        return {
          ...state,
          panelsHeight: [],
        };
      }

      const data = [...state.panelsHeight];
      Object.entries(payload).forEach((entry) => {
        data[+entry[0]] = entry[1] || 0;
      });

      return {
        ...state,
        panelsHeight: data,
      };
    case SET_UPDATING_STATUS:
      return {
        ...state,
        isUpdating: payload,
      };
    case SET_ITEM_TO_EDIT:
      return {
        ...state,
        itemToEdit: payload,
      };
    case SET_CHART_FILTERING:
      return produce(state, (draftState) => {
        draftState.chartFilter = payload;
      });
    case RECREATE_SCHEDULER_INSTANCE:
      return produce(state, (draftState) => {
        draftState.isRecreating = payload;
      });
    default:
      return state;
  }
}

export function UtilsReducer3(state = initialState3, { type, payload }) {
  switch (type) {
    case SELECT_ROW:
      return {
        ...state,
        selectedRow: payload,
      };
    case SET_TASK_TO_ASSIGN:
      return {
        ...state,
        taskToAssign: payload,
      };
    case SET_MY_SCOPES_VIEW:
      return {
        ...state,
        myScopeView: payload,
      };
    case SET_WORKLOAD_FILTERS:
      return {
        ...state,
        workloadFilters: payload,
      };
    default:
      return state;
  }
}

export function UtilsReducer4(state = initialState4, { type, payload }) {
  switch (type) {
    case DISABLE_SAVING:
      return {
        ...state,
        disableSaving: payload,
      };
    case ADD_ITEM_PENDING:
      return produce(state, (draftState) => {
        draftState.itemsPending = [...draftState.itemsPending, payload];
      });
    case REMOVE_ITEM_PENDING:
      return produce(state, (draftState) => {
        draftState.itemsPending = draftState.itemsPending.filter(
          (id) => id !== payload
        );
      });
    case CLEAR_ITEMS_PENDING:
      return produce(state, (draftState) => {
        draftState.itemsPending = [];
      });
    case SET_SELECTED_ACTIVITY:
      return produce(state, (draftState) => {
        draftState.selectedActivity = payload;
      });
    case SET_REGULAR_TO_ASSIGN:
      return produce(state, (draftState) => {
        draftState.regularToAssign = payload;
      });
    case SET_WORK_DAYS_CALENDAR:
      return produce(state, (draftState) => {
        draftState.calendar = payload;
      });
    default:
      return state;
  }
}

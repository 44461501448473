import {
  GET_RESOURCE_TASKS_REQUEST,
  GET_RESOURCE_TASKS_SUCCESS,
  GET_RESOURCE_TASKS_ERROR,
  CREATE_RESOURCE_TASK_REQUEST,
  CREATE_RESOURCE_TASK_SUCCESS,
  CREATE_RESOURCE_TASK_ERROR,
  UPDATE_RESOURCE_TASK_REQUEST,
  UPDATE_RESOURCE_TASK_SUCCESS,
  UPDATE_RESOURCE_TASK_ERROR,
  DELETE_RESOURCE_TASK_REQUEST,
  DELETE_RESOURCE_TASK_SUCCESS,
  DELETE_RESOURCE_TASK_ERROR,
  ASSIGN_RESOURCE_TASK_SUCCESS,
  ASSIGN_RESOURCE_TASK_ERROR,
  ASSIGN_RESOURCE_TASK_REQUEST,
  CHECK_IS_MANAGER_SUCCESS,
  CHECK_IS_MANAGER_ERROR,
  UPDATE_ACTIVE,
  GET_RESOURCE_TASKS_BY_SCENARIO_ID_REQUEST,
  GET_RESOURCE_TASKS_BY_SCENARIO_ID_SUCCESS,
  GET_RESOURCE_TASKS_BY_SCENARIO_ID_ERROR,
  TOP_DOWN_RESOURCE_TASK_UPDATE,
  GET_RESOURCE_TASKS_DASHBOARD_REQUEST,
  GET_RESOURCE_TASKS_DASHBOARD_SUCCESS,
  GET_RESOURCE_TASKS_DASHBOARD_ERROR,
  GET_RESOURCE_TASKS_CUSTOM_COLUMNS_REQUEST,
  GET_RESOURCE_TASKS_CUSTOM_COLUMNS_SUCCESS,
  GET_RESOURCE_TASKS_CUSTOM_COLUMNS_ERROR,
  GET_RESOURCE_TASKS_CUSTOM_COLUMN_NAMES_REQUEST,
  GET_RESOURCE_TASKS_CUSTOM_COLUMN_NAMES_SUCCESS,
  GET_RESOURCE_TASKS_CUSTOM_COLUMN_NAMES_ERROR,
  ADD_RT_CUSTOM_COLUMNS,
} from "../types";

import produce from "immer";

const initialState = {
  tasks: [],
  dashboard: [],
  isFetching: false,
  isDashboardFetching: false,
  isFetchingColumns: false,
  isFetchingColumnNames: false,
  isUpdating: false,
  isManager: false,
  customColumns: [],
  customColumnNames: [],
  error: "",
};
// eslint-disable-next-line
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_RESOURCE_TASKS_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetching = true;
        draftState.error = "";
      });
    case GET_RESOURCE_TASKS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.tasks = payload || [];
      });
    case GET_RESOURCE_TASKS_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.error = payload;
      });
    case GET_RESOURCE_TASKS_DASHBOARD_REQUEST:
      return produce(state, (draftState) => {
        draftState.isDashboardFetching = true;
        draftState.error = "";
      });
    case GET_RESOURCE_TASKS_DASHBOARD_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isDashboardFetching = false;
        draftState.dashboard = payload || [];
      });
    case GET_RESOURCE_TASKS_DASHBOARD_ERROR:
      return produce(state, (draftState) => {
        draftState.isDashboardFetching = false;
        draftState.error = payload;
      });
    case CREATE_RESOURCE_TASK_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: "",
      };
    case CREATE_RESOURCE_TASK_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        tasks: [...state.tasks, payload],
      };
    case CREATE_RESOURCE_TASK_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: "",
      };
    case ASSIGN_RESOURCE_TASK_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: "",
      };
    case ASSIGN_RESOURCE_TASK_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        tasks: [...state.tasks, payload.child].map((val) => {
          const isUpdated = payload.updatedTasks.some(
            ({ resourceTask }) => resourceTask.id === val.resourceTask.id
          );
          if (!isUpdated) return val;
          else
            return payload.updatedTasks.find(
              ({ resourceTask }) => resourceTask.id === val.resourceTask.id
            );
        }),
      };
    case ASSIGN_RESOURCE_TASK_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: "",
      };
    case UPDATE_RESOURCE_TASK_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: "",
      };
    case UPDATE_RESOURCE_TASK_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.tasks = [...state.tasks].map((task) => {
          if (task.resourceTask.id === payload.child.resourceTask.id)
            return payload.child;
          return task;
        });
      });
    case UPDATE_RESOURCE_TASK_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    case UPDATE_ACTIVE:
      return {
        ...state,
        isUpdating: false,
        tasks: [...state.tasks].map((task) => {
          if (task.resourceTask.id == payload) {
            task.resourceTask.isActive = !task.resourceTask.isActive;
          }
          return task;
        }),
      };
    case TOP_DOWN_RESOURCE_TASK_UPDATE:
      return produce(state, (draftState) => {
        // constrcuting an object to avoid big computations on array (find, filter etc...)
        const mapRTs = payload.reduce(
          (map, rt) => ({ ...map, [rt.id]: rt }),
          {}
        );
        draftState.tasks = draftState.tasks.map((task) => {
          const rtId = task.resourceTask.id;
          const resourceTask =
            rtId in mapRTs ? mapRTs[rtId] : task.resourceTask;
          return { resourceTask };
        });
      });
    case DELETE_RESOURCE_TASK_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: "",
      };
    case DELETE_RESOURCE_TASK_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isUpdating = false;
        draftState.tasks = draftState.tasks.reduce((newTasks, val) => {
          if (val.resourceTask.id === payload.childId) return newTasks;
          const ids = payload.updatedTasks.map(
            ({ resourceTask }) => resourceTask.id
          );
          return [
            ...newTasks,
            ids.includes(val.resourceTask.id)
              ? payload.updatedTasks.find(
                  ({ resourceTask }) => resourceTask.id === val.resourceTask.id
                )
              : val,
          ];
        }, []);
      });
    case DELETE_RESOURCE_TASK_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: payload,
      };
    case CHECK_IS_MANAGER_SUCCESS:
      return {
        ...state,
        isManager: payload,
      };
    case CHECK_IS_MANAGER_ERROR:
      return {
        ...state,
        error: payload,
      };
    case GET_RESOURCE_TASKS_BY_SCENARIO_ID_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetching = true;
        draftState.tasks = [];
      });
    case GET_RESOURCE_TASKS_BY_SCENARIO_ID_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.tasks = payload;
      });
    case GET_RESOURCE_TASKS_BY_SCENARIO_ID_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetching = false;
        draftState.error = payload;
      });
    case GET_RESOURCE_TASKS_CUSTOM_COLUMNS_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetchingColumns = true;
        draftState.error = "";
      });
    case GET_RESOURCE_TASKS_CUSTOM_COLUMNS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetchingColumns = false;
        draftState.customColumns = payload || [];
      });
    case GET_RESOURCE_TASKS_CUSTOM_COLUMNS_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetchingColumns = false;
        draftState.error = payload;
      });
    case GET_RESOURCE_TASKS_CUSTOM_COLUMN_NAMES_REQUEST:
      return produce(state, (draftState) => {
        draftState.isFetchingColumnNames = true;
        draftState.error = "";
      });
    case GET_RESOURCE_TASKS_CUSTOM_COLUMN_NAMES_SUCCESS:
      return produce(state, (draftState) => {
        draftState.isFetchingColumnNames = false;
        draftState.customColumnNames = payload || [];
      });
    case GET_RESOURCE_TASKS_CUSTOM_COLUMN_NAMES_ERROR:
      return produce(state, (draftState) => {
        draftState.isFetchingColumnNames = false;
        draftState.error = payload;
      });
    case ADD_RT_CUSTOM_COLUMNS:
      return produce(state, (draftState) => {
        draftState.customColumns = [...draftState.customColumns, ...payload];
      });
    default:
      return state;
  }
}
